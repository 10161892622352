/**
 * Third-party libraries.
 */
import { Button, Divider } from "antd";
import { useEffect, useRef, useState } from "react";
import { CommunicationLogInboundCallStatusMapper, CommunicationLogOutboundCallStatusMapper } from "@/components/client/communication-log";
import { Empty } from "@/components/client/empty/empty";
import { CallDirection, CallStatus, usePastInteractionsQuery } from "@/components/client/graphql";
import { Loading } from "@/components/client/loading";
import { RichTextEditor } from "@/components/client/rich-text/rich-text-editor";
import { PhoneIcon } from "@/components/client/svgs/icons";
import { formatDate } from "@/components/common/time/utilities/time-utility";
import Icon, { CaretDownOutlined } from "@ant-design/icons";
import Collapsible from "../collapse/collapsible";
import { FieldDisplayOnly } from "../field";

/**
 * Call Past Interactions Card Props.
 */
type CallPastInteractionsCardProps = {
  /**
   * Indicates that the card is visible.
   */
  visible?: boolean;
  /** The phone number of the client. Used to fetch the Past interactions of the client. */
  clientPhoneNumber?: string;
};

/** Card that displays the past interactions of a client. */
export function CallPastInteractionsCard(props: CallPastInteractionsCardProps) {
  /** Expanded state of the past interactions LIST. */
  const [expanded, setExpanded] = useState(false);

  // ===========================================================================
  // Queries
  // ===========================================================================

  /**
   * Query that contains the past interactions with `clientPhoneNumber` based
   * on the `to` OR `from` record.
   *
   * This will contain the currently selected call as well because the `endDate` is inclusive of the current call.
   *
   * TODO: Add pagination.
   */
  const pastInteractionsQuery = usePastInteractionsQuery({
    skip: !props?.clientPhoneNumber || !props.visible,
    variables: {
      clientPhoneNumber: props?.clientPhoneNumber
    }
  });

  // ===========================================================================
  // Derived States
  // ===========================================================================

  /** Disable when query data is still `undefined` (loading) or when there are <= 3 interactions. */
  const collapsibleIsDisabled = !pastInteractionsQuery?.data || pastInteractionsQuery?.data?.calls.length <= 3;

  // Hide if not visible.
  if (!props.visible) {
    return null;
  }
  return <div className="relative w-full animate-slide-left rounded-md border border-slate-200 bg-white" data-sentry-component="CallPastInteractionsCard" data-sentry-source-file="call-past-interactions-card.tsx">
      <div className="flex items-center justify-between border-b border-slate-200 border-b-neutral-mid-grey p-4">
        <div className="text-tpl-navy">Past Interactions</div>

        {/* Filter is out of scope for now. */}
        {/* <Button
          disabled
          type="text"
          className="relative w-6 h-6 flex justify-center items-center cursor-not-allowed opacity-60"
         >
          <Icon src="filter" className="absolute text-xl" />
         </Button> */}
      </div>

      {!pastInteractionsQuery.loading && pastInteractionsQuery?.data?.calls?.length === 0 && <Empty label="No Interactions" className="py-4" />}

      {pastInteractionsQuery.loading && <div className="flex w-full items-center justify-center" style={{
      height: 150
    }}>
          <Loading />
        </div>}

      {/* PAST INTERACTIONS LIST */}
      {pastInteractionsQuery?.data?.calls && <Collapsible
    /**
     * Always expanded. We only change the slice for now.
     */ collapsed={false} className="flex w-full flex-col gap-y-4 p-4 !duration-700"
    /**
     * Dynamic Height gives this the animation we need when expanding.
     * Or changing the amount of list items.
     */ dynamicHeight>
          {pastInteractionsQuery.data.calls.slice(0, expanded ? undefined : 3).map(_call => <PastInteractionItemCard key={_call.id} direction={_call.direction} status={_call.status} summary={_call.summary} dateStarted={_call.date} agentName={_call.user?.profile?.fullName ?? ""} />)}
        </Collapsible>}

      {/* COLLAPSE BUTTON, there's no UI on Figma for this so feel free to change it. */}
      {!collapsibleIsDisabled && <Button className="!absolute bottom-0 left-1/2 h-5 w-5 -translate-x-1/2 hover:scale-110" onClick={() => setExpanded(!expanded)}>
          <CaretDownOutlined className={`transition-transform ${expanded ? "rotate-180" : ""}`} />
        </Button>}
    </div>;
}

// ===========================================================================
// Past Interaction Card
// ===========================================================================

/** Props for PastInteractionItemCard */
type PastInteractionCardItemProps = {
  status: CallStatus;
  direction: CallDirection;
  summary?: string | null;
  dateStarted: Date | string;
  agentName: string;
};

/** A single card inside past interactions list. */
export function PastInteractionItemCard({
  status,
  direction,
  summary,
  dateStarted,
  agentName
}: PastInteractionCardItemProps) {
  /** Expanded state of the past interactions card ITEM SUMMARY. */
  const [expanded, setExpanded] = useState(false);

  /** True if css clamp is active. */
  const [clampIsActive, setClampIsActive] = useState(false);
  const clampedElementRef = useRef<HTMLSpanElement>(null);

  /** Past interactions card title based on call status. */
  const cardTitle = function () {
    if (direction === CallDirection.Inbound) {
      const mappedStatus = CommunicationLogInboundCallStatusMapper[status as keyof typeof CommunicationLogInboundCallStatusMapper];
      if (mappedStatus) {
        return `${mappedStatus} - ${direction}`;
      }
      return "Unknown Call";
    } else if (direction === CallDirection.Outbound) {
      const mappedStatus = CommunicationLogOutboundCallStatusMapper[status as keyof typeof CommunicationLogOutboundCallStatusMapper];
      if (mappedStatus) {
        return `${mappedStatus} - ${direction}`;
      }
      return "Unknown Call";
    }
  }();

  /** Card's top-right badge based on call status. */
  const badge = function () {
    switch (status) {
      case CallStatus.Completed:
        return null;
      /**
       * "Resolved" tag is temporary and currently out of scope.
       * https://thepiquelab.atlassian.net/browse/ARCD-4341
       */
      // <Tag color="success" className="!text-xs mx-0 !me-0">
      //   Resolved
      // </Tag>
      default:
        return null;
    }
  }();

  /** Color of the phone icon based on call status. */
  const phoneIconColorClass = function () {
    switch (status) {
      case CallStatus.Completed:
        return "!text-semantic-green";
      case CallStatus.Missed:
      case CallStatus.Canceled:
        return "!text-semantic-red";
      default:
        return "";
    }
  }();

  // For detecting if CSS line-clamp-3 is active (e.g. currently showing `...` via CSS).
  // When active (clamped):
  // - Collapsed: Show 3 lines
  // - Expanded: Show all lines
  // When not active (not clamped):
  // - Collapsed: Show all lines
  // - Expanded: Show all lines
  useEffect(() => {
    if (!clampedElementRef.current) return;

    /**
     * To resolve an eslint warning when trying to access the ref in the cleanup.
     * Solution is to make declare a local variable and use that in the cleanup instead.
     * This is because accessing `clampedElementRef.current` directly is erratic.
     */
    let _clampedElementRef = clampedElementRef.current;
    const isTextClamped = (elm: HTMLSpanElement) => elm.scrollHeight > elm.clientHeight;
    const observer = new ResizeObserver(e => {
      if (!clampedElementRef.current) return;
      setClampIsActive(isTextClamped(clampedElementRef.current));
    });
    observer.observe(_clampedElementRef);
    return () => {
      observer.unobserve(_clampedElementRef);
    };
  }, []);
  return <div className="bg-neutral-surface-gray flex flex-col gap-y-2 rounded border border-[#E8EBF1] bg-neutral-surface-grey p-3 text-tpl-navy" data-sentry-component="PastInteractionItemCard" data-sentry-source-file="call-past-interactions-card.tsx">
      <header className="flex w-full flex-col gap-y-2">
        <div className="flex items-center justify-between gap-x-1">
          <div className="flex items-center gap-x-1">
            <Icon className={phoneIconColorClass} component={PhoneIcon} data-sentry-element="Icon" data-sentry-source-file="call-past-interactions-card.tsx" />

            <span className="text-sm text-tpl-navy">{cardTitle}</span>
          </div>

          {badge}
        </div>

        <FieldDisplayOnly label="Date" value={formatDate(dateStarted, {
        format: "DD MMM YYYY, h:mm A"
      })} data-sentry-element="FieldDisplayOnly" data-sentry-source-file="call-past-interactions-card.tsx" />

        {agentName && <FieldDisplayOnly label="Agent" value={agentName} />}
      </header>

      {summary && <>
          <Divider className="!my-0" />
          <footer className="relative flex flex-col gap-y-2">
            <span className="text-sm">Call Summary:</span>
            {/* <span
              ref={clampedElementRef}
              className={`text-xs ${expanded ? "" : "line-clamp-3"}`}
             >
              {summary}
             </span> */}
            <span ref={clampedElementRef} className={`${expanded ? "" : "line-clamp-3"}`}>
              <RichTextEditor content={summary} editable={false} />
            </span>

            {clampIsActive && !expanded && <span className="absolute bottom-0 right-0 bg-neutral-surface-grey text-sm">
                ...
                <button className="text-semantic-blue" onClick={() => {
            setExpanded(true);
          }}>
                  See More
                </button>
              </span>}

            {expanded && <span className="right-0 flex justify-end bg-neutral-surface-grey text-sm">
                <button className="text-semantic-blue" onClick={() => {
            setExpanded(false);
          }}>
                  Collapse
                </button>
              </span>}
          </footer>
        </>}
    </div>;
}