import { InboxEmptyWithShadowsIcon } from "@/components/client/svgs/icons";
import Icon from "@ant-design/icons";
import { type ReactNode } from "react";

/** Props for the Empty component. */
type EmptyProps = {
  /** Label under the Empty icon. e.g. "No interactions found". */
  label?: ReactNode;
  /** Additional Class names for the container. */
  className?: string;
};

/**
 * Displays an empty state indicator with an icon and a label.
 *
 * @example
 *       📥
 *  No mails found
 */
export function Empty({
  label,
  className
}: EmptyProps) {
  return <div className={`flex w-full flex-col items-center gap-y-2 ${className}`} data-sentry-component="Empty" data-sentry-source-file="empty.tsx">
      <Icon component={InboxEmptyWithShadowsIcon} className="!text-6xl !text-tpl-navy-light" data-sentry-element="Icon" data-sentry-source-file="empty.tsx" />
      <div className="text-sm text-tpl-navy-light">{label}</div>
    </div>;
}